import { Modal, SelectWithRef, Button } from '@lib/components'
import { useAsync } from '@lib/legacycomponents'
import { ActiveCampaign } from '@shamaazi/mytennights'
import { type FC, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { type Charity, myTenNightsCampaignCreate, UpdateCampaignTier } from '~/service'

export const CreateCampaign: FC<{ charity: Charity, campaign: string, isOpen: boolean, closeModal: () => void }> = ({ charity, isOpen, closeModal, campaign }) => {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm()
  setValue('tier', charity.tier)
  const isEdit = charity.tier !== ''
  const submit = async (data: { tier: string }): Promise<void> => {
    isEdit ? await UpdateCampaignTier(charity.charity_id, data.tier, campaign) : await myTenNightsCampaignCreate(charity.charity_id, campaign, data.tier)
    window.location.reload()
  }
  const { execute: onSubmit, loading: submitting, error: submitError } = useAsync(useCallback(submit, [charity.charity_id, isEdit, campaign]), false)

  return <Modal isOpen={isOpen} onClose={closeModal}>

        <div className='p-4'>
            <h2 className='font-bold my-4'> Set a Tier for {charity.name} for {campaign.toUpperCase()} campaign</h2>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
                <label>
                    Tier:
                    <SelectWithRef
                        variant="sz-inline"
                        className="mt-4 ml-4"
                        defaultValue=""
                        {...register('tier', { required: true, validate: { validTier: (value) => value === 'widget' || value === 'homepage' } })}
                    >
                        <option value="" disabled={true}>Choose a tier</option>
                        { campaign !== ActiveCampaign.fridayGiving && <option value="widget">Widget</option>}
                        <option value="homepage">Home page</option>
                    </SelectWithRef>
                </label>
                {(errors.tier !== undefined) && <p className="mt-4 text-sm text-right text-sz-pink">We were unable to accept the selected value</p>}

                <div className="mt-8">
                    <Button variant="sz-primary"
                        loading={submitting} disabled={submitting}
                    >
                        {isEdit ? 'UPDATE' : 'CREATE'} CAMPAIGN
                    </Button>
                </div>
                {submitError !== null && <p className="text-sm text-right text-sz-pink">We were unable to create the campaign.</p>}
            </form>
        </div>
    </Modal>
}
