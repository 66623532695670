import { withAuth } from '@lib/hooks'
import { type MyTenNightsCause } from './mytennights'

interface Representatives {
  name: string
  email: string
  phone_number: string
  position: string
}

export interface Charity {
  charity_id: string
  country: string
  name: string
  registration_number: string
  representatives: Representatives[]
  status: string
  created: string
  tier: string
  causes: Record<string, MyTenNightsCause>
}
const APPROVED = 'approved'
const PENDING = 'pending'

export const loadPendingCharities = withAuth(async (campaign: string): Promise<Charity[]> => {
  return loadCharities(PENDING, campaign)
})
const loadCharities = withAuth(async (status: string, campaign: string): Promise<Charity[]> => {
  const res = await fetch(`/api/charity/${status}/${campaign}`)

  if (res.status !== 200) {
    throw new Error(`Unexpected status ${res.status}`)
  }

  return await res.json() ?? []
})

export const loadApprovedCharities = withAuth(async (campaign: string): Promise<Charity[]> => {
  return loadCharities(APPROVED, campaign)
})

export const loadAllCharities = withAuth(async (campaign: string): Promise<Charity[]> => {
  const approved = await loadApprovedCharities(campaign)
  const pending = await loadPendingCharities(campaign)
  return [...approved, ...pending]
})

interface AccountIDs {
  charity_id: string
  account_id: string
  linked_charity: string
}

export const loadCharityAccounts = withAuth(async (): Promise<AccountIDs[]> => {
  const res = await fetch('/api/accounts')

  if (res.status !== 200) {
    throw new Error(`Unexpected status ${res.status}`)
  }

  return await res.json()
})

export const rejectCharity = withAuth(async (charityID: string): Promise<void> => {
  const res = await fetch(`/api/charity/${charityID}/reject`, {
    method: 'POST'
  })

  if (res.status !== 200) {
    throw new Error(`Unexpected status ${res.status}`)
  }

  return await res.json()
})

export const approveCharity = withAuth(async (charityID: string): Promise<void> => {
  const res = await fetch(`/api/charity/${charityID}/approve`, {
    method: 'POST'
  })

  if (res.status !== 200) {
    throw new Error(`Unexpected status ${res.status}`)
  }

  return await res.json()
})
