import { type FC, useCallback, useState } from 'react'
import { useAsync } from '@lib/legacycomponents'
import { Button, Input, Page } from '@lib/components'
import { type Charity, loadAllCharities } from '~/service/charity'
import { type Products } from '@shamaazi/mytennights'
import { CreateCampaign } from '~/components/CreateCampaign'
import { useDebounce } from '@lib/hooks'
import { CharityStatus } from '@lib/services'

export interface CharityListProps {
  readonly product: Products
  readonly currentCampaign: string
}

export const CharityList: FC<CharityListProps> = (props) => {
  const { result: charities, loading, error } = useAsync<Charity[]>(useCallback(async () => await loadAllCharities(props.currentCampaign), [props.currentCampaign]))
  const [charityToEdit, setCharityToEdit] = useState<Charity>()
  const [openModal, setOpenModal] = useState(false)
  const [searchText, setSearchText] = useState('')
  const debouncedSearchText = useDebounce(searchText)

  const InfoCard: FC<{ title: string, label: string }> = ({ title, label }) => {
    return <div className='flex flex-col'>
      <div>{title}</div>
      <div className=' text-sm text-sz-500'>{label}</div>
    </div>
  }

  const renderModal = (charity: Charity): void => {
    setCharityToEdit(charity)
    setOpenModal(true)
  }

  const charityList = (charities ?? []).filter(
    c => (c.name.toLowerCase().includes(debouncedSearchText) || c.status.toLowerCase().includes(debouncedSearchText) || c.tier.toLowerCase().includes(debouncedSearchText))
  ).map((c, i) => {
    const isAnyCauseActive = Object.values(c.causes ?? []).filter(cause => cause.active).length > 0
    const causes = Object.keys(c.causes ?? [])

    return <div className={`grid grid-cols-7 gap-x-4 py-4 text-center ${i % 2 === 0 ? 'bg-sz-gray-50' : ''}`}>
      <div> {c.name} </div>
      <InfoCard title={c.tier !== '' ? c.tier : 'N/A'} label={c.tier !== '' ? '' : 'tier not set'} />

      <div> {c.status} </div>
      <InfoCard title={c.representatives.length > 0 ? 'Yes' : 'No'} label={`${c.representatives.length} user(s) added`} />
      <InfoCard title={c.causes ? 'Yes' : 'No'} label={`${causes?.length ?? 0} cause(s) added`} />
      <InfoCard title={isAnyCauseActive ? 'Yes' : 'No'} label={`${isAnyCauseActive ? '' : 'No'} active cause`} />
      <div> {(c.status === CharityStatus.approved) && <Button className='py-2' onClick={() => renderModal(c)} variant={c.tier === '' ? 'gm-primary-md' : 'gm-secondary-md'}>{c.tier === '' ? 'Setup' : 'Update'} Tier</Button>} </div>
    </div>
  })

  return <Page wide className="px-4">
    { props.currentCampaign !== 'fridaygiving' ? <h1 className="mt-8 font-mono text-lg font-bold text-sz-gray-700">Charities availabe for {props.currentCampaign.toUpperCase()} campaign</h1> : <h1 className="mt-8 font-mono text-lg font-bold text-sz-gray-700">Charities availabe for MyFridays campaign</h1>}
    {charityToEdit && <CreateCampaign charity={charityToEdit} campaign={props.currentCampaign} isOpen={openModal} closeModal={() => setOpenModal(false)} />}
    <div className='flex  py-4 gap-x-4 w-full justify-end'>
      <label className="flex items-center  justify-end">
        <Input
          variant="sz"
          className="flex-1 "
          type="text"
          name="name"
          onChange={(e) => setSearchText(e.currentTarget.value)}
          value={searchText}
          placeholder="Filter..."
        />
      </label>
    </div>
    {loading && <h2 className="mt-4 font-mono text-sz-gray-700">Loading...</h2>}
    {!loading && error !== null && <h2 className="text-sz-pink">Oops! We were unable to load any charities</h2>}
    {!loading && error == null && <>
      <div className='bg-white '>
        {charityList}
      </div>
      {(charityList ?? []).length === 0 && <p>There are no charities to display</p>}
    </>}
  </Page>
}
