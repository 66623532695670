import { type FC, useCallback } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useAsync } from '@lib/legacycomponents'
import { displayCurrency } from '@lib/services'
import { listMatchPots, type MatchPot } from '~/service/match_pot'
import { Button } from '@lib/components'

export const CharityMatchPots: FC = () => {
  const navigate = useNavigate()
  const { charityID } = useParams() as { charityID: string }
  const { result: matchPots, error, loading } = useAsync<MatchPot[]>(useCallback(async () => await listMatchPots(charityID), [charityID]))

  const emptyMatchPots: MatchPot[] = []
  const MatchPots = (matchPots ?? emptyMatchPots).map(m => {
    const remaining = displayCurrency({ amount: m.currentAmount, currency: m.currency, decimalPlaces: 0 })
    const total = displayCurrency({ amount: m.totalAmount, currency: m.currency, decimalPlaces: 0 })

    return <div className="flex flex-col p-6 my-4 bg-white shadow-lg rounded-xl" key={m.matchPotID}>
      <p>{m.name}</p>
      <p className="mt-2">
        <span className="text-lg font-bold text-sz-gray-700">{remaining}</span> remaining out of <span className="text-lg font-bold text-sz-gray-700">{total}</span>
      </p>
    </div>
  })

  return <main>
    <div className="mx-4 my-4 underline">
      <Link to="/match-pots">&lt; Back to all charities</Link>
    </div>
    <div className="flex flex-col max-w-3xl px-4 mx-auto">
      <h1 className="font-mono text-lg font-bold text-sz-gray-700">{charityID}</h1>
      {loading && <p className="mt-4 font-mono text-sz-gray-700">Loading...</p>}
      {error !== null && <p className="mt-4 text-sm text-sz-pink">Oops! We were unable to load the match pots for this charity</p>}
      {(!loading && error == null) && <>
        {MatchPots}
        {MatchPots.length === 0 && <p className="mt-4">There are no match pots for this charity</p>}
        <div className="flex justify-center mt-4">
          <Button variant="sz-outline" onClick={() => navigate(`/match-pots/${charityID}/create`)}>
            ADD MATCH POT
          </Button>
        </div>
      </>}
    </div>
  </main>
}
