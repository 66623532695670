import * as Sentry from '@sentry/react'
import { type FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '@lib/hooks'

export const Header: FC = () => {
  const [active, setActive] = useState(false)
  const { user, logout } = useAuth()

  // Identify impacted user
  if (user !== null) {
    // Record breadcrumb
    Sentry.addBreadcrumb({
      category: 'auth.login',
      message: 'Authenticated user ' + user.email,
      level: 'info'
    })

    // Event enriching
    // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/identify-user/
    Sentry.setUser({ email: user.email })
  }

  const onLogoutClick = (): void => {
    // Record breadcrumb
    Sentry.addBreadcrumb({
      category: 'auth.logout',
      message: 'Logged out user ' + user!.email,
      level: 'info'
    })

    // Logout logic
    setActive(false)
    logout()

    // Event enriching
    Sentry.setUser(null)
  }

  return <>
    <header className="flex px-4 py-8 bg-white shadow-lg md:px-8">
      <Link className="hover:text-sz-gray-700" to={'/'}>Shamaazi Admin</Link>

      <div className="flex-1 hidden text-right md:block">
        <Link className="px-4 hover:text-sz-gray-700" to="/match-pots">Match Pots</Link>
        <Link className="px-4 hover:text-sz-gray-700" to="/accounts">Stripe Accounts</Link>
        <Link className="px-4 hover:text-sz-gray-700" to="/mytennights">MyTenNights</Link>
        <Link className="px-4 hover:text-sz-gray-700" to="/mytendays">MyTenDays</Link>
        <Link className="px-4 hover:text-sz-gray-700" to="/myfridays">MyFridays</Link>
        {/* eslint-disable @typescript-eslint/no-floating-promises */}
        {user !== null && <Link
          className="px-4 hover:text-sz-gray-700"
          onClick={onLogoutClick}
          to="/">Log Out</Link>}
        {/* eslint-enable @typescript-eslint/no-floating-promises */}
      </div>

      <div className={`md:hidden ml-auto tham tham-e-squeeze tham-w-8 ${active ? 'tham-active' : ''}`} onClick={() => setActive(!active)}>
        <div className="tham-box">
          <div className="tham-inner" />
        </div>
      </div>
    </header>

    <div
      className={`md:hidden bg-white text-center px-8 fixed min-h-screen w-full z-40 font-medium transform transition-all duration-500 left-full ${active ? '-translate-x-full' : ''}`}>
      <Link onClick={() => setActive(false)} className="block py-12 text-lg uppercase hover:text-sz-gray-700 " to="/match-pots">Match Pots</Link>
      <Link onClick={() => setActive(false)} className="block py-12 text-lg uppercase border-t hover:text-sz-gray-700 border-sz-gray-700" to="/accounts">Stripe Accounts</Link>
      <Link onClick={() => setActive(false)} className="block py-12 text-lg uppercase border-t hover:text-sz-gray-700 border-sz-gray-700" to="/mytennights">MyTenNights</Link>
      <Link onClick={() => setActive(false)} className="block py-12 text-lg uppercase border-t hover:text-sz-gray-700 border-sz-gray-700" to="/mytendays">MyTenDays</Link>
      <Link onClick={() => setActive(false)} className="block py-12 text-lg uppercase border-t hover:text-sz-gray-700 border-sz-gray-700" to="/myfridays">MyFridays</Link>
      {/* eslint-disable @typescript-eslint/no-floating-promises */}
      {user !== null && <Link
        className="block py-12 text-lg uppercase border-t hover:text-sz-gray-700 border-sz-gray-700"
        onClick={onLogoutClick}
        to="/">Log Out</Link>}
      {/* eslint-enable @typescript-eslint/no-floating-promises */}
    </div>
  </>
}
