import { type FC, useCallback, useState } from 'react'

import { useAsync } from '@lib/legacycomponents'

import { approveCharity, type Charity, loadPendingCharities, rejectCharity } from '~/service/charity'
import { PendingCharitiesList } from '~/components/PendingCharitiesList'
import { CampaignSetup } from '@shamaazi/mytennights'

interface UseCharity {
  charities: Charity[]
  loading: boolean
  error: Error | null
  onApprove: (c: Charity) => Promise<void>
  onReject: (c: Charity) => Promise<void>
}

const useCharities = (): UseCharity => {
  const [charities, setCharities] = useState<Charity[]>([])

  const { loading, error } = useAsync(
    useCallback(async () => {
      const c = await loadPendingCharities(CampaignSetup.currentCampaign) // fetches pending charities for MTN and MTD irrespectively
      c.sort((a: Charity, b: Charity) => Date.parse(a.created) - Date.parse(b.created))
      setCharities(c)
    }, [setCharities])
  )

  const onApprove = async (charity: Charity): Promise<void> => {
    if (!window.confirm(`Are you sure you wish to approve ${charity.name}?`)) {
      return
    }
    await approveCharity(charity.charity_id)
    setCharities(charities.filter((c) => c.charity_id !== charity.charity_id))
  }

  const onReject = async (charity: Charity): Promise<void> => {
    if (!window.confirm(`Are you sure you wish to REJECT ${charity.name}?`)) {
      return
    }

    await rejectCharity(charity.charity_id)
    setCharities(charities.filter((c) => c.charity_id !== charity.charity_id))
  }

  return { charities, loading, error, onApprove, onReject }
}

export const Home: FC = () => {
  const { charities, loading, error, onApprove, onReject } = useCharities()

  return <main>
    <div className="flex flex-col max-w-3xl px-4 mx-auto">
      <h1 className="mt-8 font-mono text-lg font-bold text-sz-gray-700">Charities Pending Approval</h1>
      {loading && <h2 className="mt-4 font-mono text-sz-gray-700">Loading...</h2>}
      {error !== null && <h2 className="mt-4 text-lg text-sz-pink">Oops! An error occurred</h2>}
      {!loading && error === null && <PendingCharitiesList onApprove={onApprove} onReject={onReject} charities={charities} />}
    </div>
  </main>
}
