import { type FC, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { Button, InputWithRef } from '@lib/components'
import { useAsync } from '@lib/legacycomponents'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { createCharityMatchPot, type CreateMatchPotInputs } from '~/service/match_pot'

export const CreateMatchPot: FC = () => {
  const { charityId } = useParams() as { charityId: string }
  const { register, handleSubmit, formState: { errors } } = useForm()
  const navigate = useNavigate()

  const submit = async (data: CreateMatchPotInputs): Promise<void> => {
    await createCharityMatchPot(charityId, data)
    navigate(`/match-pots/${charityId}`)
  }

  const { loading, error, execute: onSubmit } = useAsync(useCallback(submit, [charityId, navigate]), false)

  return <main>
    <div className="mx-4 my-4">
      <Link className="underline" to={`/match-pots/${charityId}`}>&lt; Back to {charityId}</Link>
    </div>

    <div className="flex flex-col max-w-3xl px-4 mx-auto my-4">
      <h1 className="font-mono text-lg font-bold text-sz-gray-700">{charityId}</h1>
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4">
          <label className="items-center sm:flex">
            Match Pot Name:
            <InputWithRef
              variant="sz"
              className="flex-1 w-full sm:ml-4"
              type="text"
              {...register('name', { required: true, validate: { notEmpty: (value) => value.trim().length !== 0 } })}
              invalid={errors.name !== undefined} />
          </label>
          {(errors.name !== undefined) && <div className="text-sm text-right text-sz-pink">We were unable to accept that name.</div>}
        </div>

        <div className="mt-4">
          <label className="items-center sm:flex">
            Starting Amount (Charity's default currency):
            <InputWithRef
              variant="sz"
              className="flex-1 w-full sm:ml-4"
              type="number"
              step="1"
              min="1"
              {...register('amount', { required: true, min: 1 })}
              invalid={errors.amount !== undefined} />
          </label>
          {(errors.amount !== undefined) && <div className="mt-4 text-sm text-right text-sz-pink">We weren't able to accept that, we only accept whole numbers greater than 1.</div>}
        </div>

        <div className="flex justify-end mt-4">
          <Button variant="sz-primary" disabled={loading} loading={loading}>
            CREATE
          </Button>
          {error !== null && <div className="mt-2 text-sm text-sz-pink">We were unable to create the match pot.</div>}
        </div>

      </form>

    </div>
  </main>
}
