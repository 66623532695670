import { type FC, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useAsync } from '@lib/legacycomponents'
import { loadApprovedCharities } from '~/service/charity'
import { CircleChecked } from '@lib/components'

interface Charity {
  charity_id: string
  name: string
  country: string
}

export const MatchPotCharityList: FC = () => {
  const { result: charities, loading, error } = useAsync<Charity[]>(useCallback(loadApprovedCharities, []))

  const emptyCharities: Charity[] = []
  const Charities = (charities ?? emptyCharities).map((c: Charity) => {
    const href = `/match-pots/${c.charity_id}`

    const displayName = `${c.name} - (${c.country})`
    return <li className="flex items-center my-2" key={c.charity_id}>
      <CircleChecked className="mr-2" />
      <Link className="underline" to={href}>{displayName}</Link>
    </li>
  })

  return <div className="flex flex-col max-w-3xl px-4 mx-auto">
    <h1 className="mt-8 font-mono text-lg font-bold text-sz-gray-700">Select a charity to view match pots</h1>
    {loading && <h2 className="mt-4 font-mono text-md text-sz-gray-700">Loading...</h2>}
    {error !== null && <h2 className="mt-4 text-sz-pink">Oops! We were unable to load any charities</h2>}
    {(!loading && error == null) && <>
      <ul className="flex flex-col my-4">
        {Charities}
      </ul>
      {Charities.length === 0 && <p>There are no charities to display</p>}
    </>}
  </div>
}
