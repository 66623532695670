import { useCallback } from 'react'
import { useAsync } from '@lib/legacycomponents'
import { CircleChecked } from '@lib/components'
import { loadCharityAccounts } from '~/service/charity'


const unslugify = (slug) => {
  const words = slug.split('-')
  return words
    .map((word, i) => {
      if (i === words.length - 1) {
        // upper case the region (UK, US, AU, ZA)
        return word.toUpperCase()
      }

      if (word === '') {
        return word
      }

      return word[0].toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export const CharityStripeAccounts = () => {
  const { result: accounts, loading, error } = useAsync(useCallback(loadCharityAccounts, []))

  const Accounts = (accounts ?? []).map(a => {
    const href = `https://dashboard.stripe.com/connect/accounts/${a.account_id}`
    return <li className="flex items-center my-2" key={a.charity_id}>
      <CircleChecked className="mr-2 text-sz-pink" />
      <a className="underline" href={href} target="_blank" rel="noopener noreferrer">{unslugify(a.charity_id)}</a>
    </li>
  })
  return <main>
    <div className="flex flex-col max-w-3xl px-4 mx-auto">
      <h1 className="mt-8 font-mono text-lg font-bold text-sz-gray-700">Charity Stripe Accounts</h1>
      {loading && <h2 className="mt-4 text-base text-sz-gray-700">Loading...</h2>}
      {error && <h2 className="text-sz-pink">Oops! An error occurred</h2>}
      {!loading && !error && <p className="mt-4">There are no Stripe accounts to display</p>}
      {!loading && !error && <ul className="flex flex-col">
        {Accounts}
      </ul>}
    </div>
  </main>
}
